import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { videosApi } from "../../../redux/apis/videosApi";

const Videos = () => {
  const { data: videosData, isLoading: loading } = videosApi.useGetAllVideosQuery();
  const [activeVideo, setActiveVideo] = useState(null); // State to track the currently playing video
 
   if(loading){
    return <p>Laoding..</p>
   }


   const videos = videosData?.data || [];


  // Function to handle video play
  const handleVideoClick = (youtubeId) => {
    // If the same video is clicked, just toggle it
    if (activeVideo === youtubeId) {
      setActiveVideo(null); // Stop the video
    } else {
      setActiveVideo(youtubeId); // Play the new video
    }
  };

  return (
    <div className=" container">
      {/* Search and Controls Row */}
      <div className="flex items-center space-x-4 justify-between mb-4">
        <h3 className=" text-2xl">All Videos</h3>

        <Link
          to={`/dashboard/gallery-videos-upload`}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-3"
        >
          Upload Video
        </Link>
      </div>

      {/* Video List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {videos.map(video => (
          <div key={video._id} className="border rounded-md p-4 bg-white shadow-md">
            <iframe
              width="100%"
              height="200"
              src={`https://www.youtube.com/embed/${video.youtubeId}${activeVideo === video.youtubeId ? '' : '?autoplay=0'}`}
              title={video.title}
              frameBorder="0"
              allowFullScreen
              onClick={() => handleVideoClick(video.youtubeId)}
            ></iframe>
            <h4 className="mt-2 text-lg font-semibold">{video.title}</h4>
            <p className="text-gray-600">{video.description}</p>
           <span className=" flex justify-between gap-2">
           <p className="text-sm text-gray-500">Views: {video.views}</p>
           <p className="text-sm text-gray-500"> {new Date(video.dateAdded).toLocaleDateString()}</p>
           </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Videos;
