import React from 'react'
import service2 from '../../images/service/img-2.png'
import './style.css'
import { developmentApi } from "../../redux/apis/developmentApi";

const Service = () => {
    const { data: devData } = developmentApi.useGetDevelopmentByLatestOneQuery();


   const development = devData?.data?.development;
   const totalDevelopment = devData?.data?.totalDevelopment;
   const lastYearTotalDevelopment = devData?.data?.lastYearTotalDevelopment;


    return(
        <div className="service-area section-padding">
            <div className="container">
           
                <div className="service-wrap-s2">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <img src={development?.image } alt={development?.title} className=' h-full w-full'/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="service-item">
                                <div className="service-title">
                                    <span>{development?.title}</span>
                                    <h2>{development?.subtitle}</h2>
                                    <p>{development?.description}</p>
                                </div>
                                <div className="wpo-counter-grids">
                                    <div className="grid">
                                        <div>
                                            <h2><span className="odometer" data-count="45">{lastYearTotalDevelopment}</span>+</h2>
                                        </div>
                                        <p>New Mosque</p>
                                    </div>
                                    <div className="grid">
                                        <div>
                                            <h2><span className="odometer" data-count="30">{totalDevelopment}</span>+</h2>
                                        </div>
                                        <p>Old Mosque</p>
                                    </div>
                                    {/* <div className="grid">
                                        <div>
                                            <h2><span className="odometer" data-count="85">85</span>+</h2>
                                        </div>
                                        <p>Imams</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;