import React from "react";
import { useForm } from "react-hook-form";
import { FaAlignJustify, FaUpload } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { videosApi } from "../../../redux/apis/videosApi";
import { categoryApi } from "../../../redux/apis/categoryApi";

const UploadVideo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [ createVideo ] = videosApi.useCreateVideoMutation();
  const { data: categoryData } = categoryApi.useGetAllCategoryQuery();

  const categories = categoryData?.data?.categories || [];


  // Function to extract YouTube video ID from URL
  const extractYoutubeId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null; // Return the YouTube ID or null if not found
  };

  // Handle upload video function
  const handleVideoUpload = async (data) => {
    try {
      const youtubeId = extractYoutubeId(data?.youtubeUrl);
      if (!youtubeId) {
        toast.error("Invalid YouTube URL");
        return;
      }

      const  videoInfo = { 
        title: data?.title,
        description: data?.description,
        youtubeId: youtubeId,
        category: data?.category
      }

      const res = await createVideo(videoInfo);
      console.log(res)
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        reset();
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error uploading video: ", error);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-5 rounded-md w-full max-w-2xl mb-5">
      <div className="flex justify-between">
        <h3 className="text-xl font-semibold">Add Video</h3>
        <Link
          className="text-white bg-green-400 rounded-md flex gap-1 py-2 items-center px-3 w-24 mb-2"
          to={`/dashboard/gallery-videos`}
        >
          <FaAlignJustify className=" text-xl "/> Videos
        </Link>
      </div>
      <form onSubmit={handleSubmit(handleVideoUpload)}>
        <div>
          {/* Video Title Input */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="videoTitle">
              Video Title
            </label>
            <input
              type="text"
              className="mt-1 block w-full px-3 py-2 bg-white  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Video Title"
              {...register("title", { required: true })}
            />
            {errors.title && <span className="text-red-500">This field is required</span>}
          </div>

          {/* Video Description Input */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="videoDescription">
              Video Description
            </label>
            <textarea
              id="videoDescription"
              className="mt-1 block w-full px-3 py-2 bg-white  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Video Description"
              {...register("description")}
            />
          </div>

          {/* YouTube URL Input */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="youtubeUrl">
              YouTube Video URL
            </label>
            <input
              type="text"
              id="youtubeUrl"
              className="mt-1 block w-full px-3 py-2 bg-white  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter YouTube video URL"
              {...register("youtubeUrl", { required: true })}
            />
            {errors.youtubeUrl && <span className="text-red-500">This field is required</span>}
          </div>

  

          {/* Category Input */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="category">
              Category
            </label>
            <select
              id="category"
              className="mt-1 block w-full px-3 py-2 bg-white  border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("category")}
            >
              {
                categories?.length > 0 && categories?.map(( cat) =>  <option key={cat?._id} value={cat?._id}>{cat?.name}</option> )
              }
            </select>
          </div>

          <button
            type="submit"
            className="py-2 px-3 mt-3 bg-blue-500 hover:bg-blue-700 rounded-md flex gap-2 text-white dark:bg-gray-700 items-center text-center"
          >
            Upload <FaUpload className=" text-sm" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadVideo;
