import React from "react";
import { newsletterApi } from "../../../redux/apis/newsletterApi";


const Subscribers = () => {
    const { data: subscriberData, isLoading } = newsletterApi.useGetAllSubscribersQuery();
    const subscribers = subscriberData?.data?.subscribers || [];


   if(isLoading){
    return <p>Loading subscriber..</p>
   }

    return (
        <div className=" container mt-5">
            All Subscribers

               <div className=" overflow-x-auto">
     <table className="table table-zebra">
       {/* head */}
       <thead>
         <tr>
           <th></th>
           <th>Email</th>
           <th>Subscribed At</th>
         </tr>
       </thead>
       <tbody>
         {/* row 1 */}
         {
           subscribers?.length > 0 ? subscribers?.map((sub, i) =>   ( <tr key={sub?._id} className=" bg-white ">
           <th>{i + 1}</th>
           <td>{sub?.email } </td>
           <td>{new Date(sub?.createdAt).toLocaleString() }</td>
         </tr> )) : 
         <p> No subscriber found</p>
         }

       </tbody>
     </table>
     </div>  
    </div>
    );
};

export default Subscribers;