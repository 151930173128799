import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { developmentApi } from "../../../redux/apis/developmentApi";


const Developments = () => {
   const { data: devData, isLoading, refetch } = developmentApi.useGetAllDevelopmentsQuery();
   const [deleteDevelopment ] = developmentApi.useDeleteDevelopmentMutation();

   const developments = devData?.data?.developments || [];


//    handle delete developments
   const handleDeleteDevelopment = async (id, title) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${title}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDevelopment(id).then((res) => {
          if (res.data)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${title} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container mt-5">
          <div className=" flex justify-between  md:px-4 mb-3 ">
            <h3 className=" text-xl font-semibold">All developments</h3>
            <Link to={`/dashboard/add-development`} className=" px-2 py-1 bg-blue-500 hover:bg-blue-700 rounded text-white text-[16px]">Add New Development</Link>
          </div>
<div className="container overflow-x-auto">
  <table className="table table-zebra">
    {/* head */}
    <thead>
      <tr>
        <th>Si</th>
        <th>Image</th>
        <th>Title</th>
        <th>Subtitle</th>
        <th>Created at</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}

      {
        (!isLoading && developments) ?   (developments?.map((development, i) =>  (<tr key={development?._id}>
        <th>{i + 1}</th>
        <td> <img src={development?.image} alt=""  className=" w-14 h-14"/></td>
        <td>{development?.title}</td>
        <td>{development?.subtitle}</td>
        <td>{new Date(development?.createdAt).toLocaleString()}</td>
        <td className=" flex gap-2 items-center justify-center my-auto">
          <Link to={`/dashboard/update-development/${development?._id}`}> <FaEdit className=" text-green-500 text-xl "/> </Link>
          <MdOutlineDeleteForever onClick={( ) => handleDeleteDevelopment(development?._id, development?.title)} className=" text-rose-600 text-xl"/>
        </td>
      </tr>)))  
      : 
      <p>developments not found</p>
      }
  

    </tbody>
  </table>
</div>        </div>
    );
};

export default Developments;