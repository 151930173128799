import React, { useState } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Home',
    link: '/home',
    submenu: [
      { id: 11, title: 'Home style 1', link: '/home' },
      { id: 12, title: 'Home style 2', link: '/home2' },
      { id: 13, title: 'Home style 3', link: '/home3' },
      { id: 14, title: 'Home style 4', link: '/home4' },
    ],
  },
  { id: 2, title: 'About', link: '/about' },
  {
    id: 3,
    title: 'Service',
    link: '/service',
    submenu: [
      { id: 31, title: 'Service', link: '/service' },
      { id: 32, title: 'Service Single', link: '/service-single' },
    ],
  },
  {
    id: 4,
    title: 'Event',
    link: '/event',
    submenu: [
      { id: 41, title: 'Event', link: '/event' },
      { id: 42, title: 'Event Single', link: '/event-single' },
    ],
  },
  {
    id: 7,
    title: 'Pages',
    link: '/',
    submenu: [
      { id: 71, title: 'About', link: '/about' },
      { id: 72, title: 'Shop', link: '/shop' },
      { id: 73, title: 'Shop Single', link: '/shop-single' },
      { id: 75, title: 'Donate', link: '/donate' },
      { id: 79, title: 'Error 404', link: '/404' },
    ],
  },
  {
    id: 5,
    title: 'Blog',
    link: '/blog',
    submenu: [
      { id: 51, title: 'Blog', link: '/blog' },
      { id: 52, title: 'Blog Left sidebar', link: '/blog-left' },
      { id: 53, title: 'Blog full width', link: '/blog-fullwidth' },
      { id: 54, title: 'Blog single', link: '/blog-details' },
      { id: 55, title: 'Blog single Left sidebar', link: '/blog-details-left' },
      { id: 56, title: 'Blog single Left sidebar', link: '/blog-details-fullwidth' },
    ],
  },
  { id: 88, title: 'Contact', link: '/contact' },
];

const MobileMenu = ({formattedHeaderMenus}) => {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(0);

  const menuHandler = () => {
    setIsMenuShow(!isMenuShow);
  };

  const toggleSubMenu = (id) => {
    setIsOpen((prevId) => (prevId === id ? 0 : id));
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
        <ul className="responsivemenu">
          {formattedHeaderMenus?.map((item) => (
            <li key={item._id}>
              {item.submenu ? (
                <p onClick={() => toggleSubMenu(item.id)}>
                  {item.name}
                  {item.submenus && (
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  )}
                </p>
              ) : (
                <Link to={item.link}>{item.name}</Link>
              )}
              {item?.submenus && (
                <Collapse isOpen={item?._id === isOpen}>
                  <Card>
                    <CardBody>
                      <ul>
                        {item?.submenus.map((submenu) => (
                          <li key={submenu._id}>
                            <Link
                              onClick={ClickHandler}
                              className="active"
                              to={submenu.link}
                            >
                              {submenu.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="showmenu" onClick={menuHandler}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
    </div>
  );
};

export default MobileMenu;
