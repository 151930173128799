import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { categoryApi } from "../../../redux/apis/categoryApi";
import { usersApi } from "../../../redux/apis/usersApi";
import { eResourceApi } from "../../../redux/apis/eresourceApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const EditPdf = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const { data } = eResourceApi.useGetEresourceByIdQuery(id);
  const currentPdf = data?.data;
  const [pdfPreview, setPdfPreview] = useState(null); // State for PDF preview  
  const [pdfFile, setPdfFile] = useState(null);  
  const { data: categoryData, isLoading } = categoryApi.useGetCategoriesByTypeQuery("e-resources");
  const [ updateEresource ] = eResourceApi.useUpdateEresourceMutation();

  const categories = categoryData?.data || [];


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfFile(file)
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPdfPreview(fileReader.result); // Set PDF preview URL
      };
      fileReader.readAsDataURL(file); // Read the file as a data URL
    }
  };


  const handleEditPdf = async(data) => {
     try{
      
      const formData = {
        title: data?.title,
        categoryId: data?.categoryId
      }

      console.log(formData)

      const res = await updateEresource({ id, formData});

      if(res?.data?.success){
        toast.success(res?.data?.message)
      }
     } catch(error){
      console.log(error)
       toast.error(error?.data?.message)
     }

  }

  return (
    <div className="flex flex-row justify-center items-center min-h-screen bg-gray-100 w-full">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Edit PDF
        </h2>

        <form onSubmit={handleSubmit(handleEditPdf)} className="mb-4">
          <div className=" mb-4">
          <label
            htmlFor="title"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
             PDF title
          </label>
           <input
            type="text" {...register("title" ,{
              required: "Please enter PDF title"})} 
              placeholder="Enter pdf title"
              className=" w-full border p-2 bg-white"
              defaultValue={currentPdf?.title}
              />
           {errors.title && (
            <p className="text-red-500 text-xs italic mt-2">
              {errors.title.message}
            </p>
          )}
          </div>
          <div className=" mb-4">
          <label
            htmlFor="pdfFile"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
             PDF Category
          </label>
          <select name="" id="" className=" w-full border p-2 bg-white"
          {...register("categoryId", { required: "Category select is required"})}
          value={currentPdf?.categoryId?._id}
          >
            <option value="">Select a category</option>
            { 
              (!isLoading && categories?.length > 0 ) ? (categories?.map((cat)  => <option key={cat._id} value={cat?._id}>{cat?.name}</option>
            )) :
            <option value="Shahadah">Shahadah</option>
            }

          </select>
          </div>
          <div className="mb-4">
  <label
    htmlFor="pdfFile"
    className="block text-gray-700 text-sm font-bold mb-2"
  >
    Choose PDF File
  </label>

  {/* Display current PDF file URL if it exists */}
  {currentPdf?.pdfFile && (
    <div className="mb-2">
      <p className="text-gray-600 text-sm">Current PDF:</p>
      <a
        href={currentPdf.pdfFile}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        View Current PDF
      </a>
    </div>
  )}

  {/* File input for new PDF */}
  <input
    type="file"
    id="pdfFile"
    disabled
    accept="application/pdf"
    {...register("pdfFile", {
      validate: {
        isPDF: (file) =>
          !file || // Allow empty if the user doesn't upload a new file
          file[0]?.type === "application/pdf" ||
          "Only PDF files are allowed",
      },
    })}
    onChange={handleFileChange} // Real-time preview
    className={`w-full p-2 border  ${
      errors.pdfFile ? "border-red-500" : "border-gray-300"
    } rounded focus:outline-none focus:ring focus:border-blue-300`}
  />
  {errors.pdfFile && (
    <p className="text-red-500 text-xs italic mt-2">
      {errors.pdfFile.message}
    </p>
  )}
</div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save change
          </button>
        </div>
        </form >
      </div>

      {/* PDF Preview */}
      {pdfPreview && (
        <div className="mt-8 w-full  h-screen">
          <h3 className="text-lg font-bold text-gray-700 mb-4 text-center">
            PDF Preview
          </h3>
          <iframe
            src={pdfPreview}
            title="PDF Preview"
            className="w-full h-full border rounded shadow"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default EditPdf;
