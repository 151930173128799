import React from "react";
import { useState } from "react";
import { videosApi } from "../../redux/apis/videosApi";

const VideosPage = () => {
  const [activeVideo, setActiveVideo] = useState(null); // State to track the currently playing video
  const { data: videosData, isLoading: loading } = videosApi.useGetAllVideosQuery();
  

  if(loading){
    return <p>Laoding..</p>
   }


   const videos = videosData?.data || [];


  // Function to handle video play
  const handleVideoClick = (youtubeId) => {
    // If the same video is clicked, just toggle it
    if (activeVideo === youtubeId) {
      setActiveVideo(null); // Stop the video
    } else {
      setActiveVideo(youtubeId); // Play the new video
    }
  };

  return (
    <div className=" mt-2 container">
    
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-4">
        {videos?.length &&  videos?.map((video) => (
          <div key={video?._id}>
            <div className="relative bg-white dark:bg-gray-700 shadow rounded overflow-hidden w-[100%] h-[266px] ">
              <div className="relative aspect-w-16 h-36">
                <iframe
                  className="w-full h-full"
                  src={`https://www.youtube.com/embed/${video.youtubeId}${
                    activeVideo === video.youtubeId ? "" : "?autoplay=0"
                  }`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onClick={() => handleVideoClick(video.youtubeId)}
                ></iframe>

                {/* YouTube icon overlay */}
                <div className="absolute top-2 left-2">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
                    alt="YouTube Icon"
                    className="w-6 h-6" // Adjust size here for small icon
                  />
                </div>
              </div>
              <div className="p-4">
                <h3 className=" font-medium mb-2">{video?.title}</h3>

                <span className=" flex justify-between gap-2 absolute bottom-4">
                  <p className="text-sm text-gray-500">Views: {video.views}</p>
                  <p className="text-sm text-gray-500 ">
                    {" "}
                    {new Date(video.dateAdded).toLocaleDateString()}
                  </p>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideosPage;
