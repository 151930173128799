import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";
import { FaSearch } from "react-icons/fa";
import { headerApi } from "../../redux/apis/headerApi";

const Header = () => {
  const [activeLink, setActiveLink] = useState("/"); // State to track active link
  const id = "674c3b5e65e16a01d9861216";
  const { data: headerData } = headerApi.useGetHeaderByIdQuery(id);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = (path) => {
    setActiveLink(path); // Set the active link's path
    window.scrollTo(10, 0);
  };

  const headerInfo = headerData?.data;
  const emailAndPhone = {
    email: headerInfo?.email,
    phone: headerInfo?.helpLineNumber,
  };

  const headerMenus = headerInfo?.headerMenus || [];

  const formattedHeaderMenus = headerMenus.map((menu) => {
    const baseURL1 = "https://aqsfoundation.org";
    const baseURL2 = "https://www.aqsfoundation.org";
  
    // Helper function to replace base URL with an empty string
    const replaceBaseURL = (url) => {
      return url.startsWith(baseURL1)
        ? url.replace(baseURL1, "")
        : url.startsWith(baseURL2)
        ? url.replace(baseURL2, "")
        : url; // Keep original URL if no match
    };
  
    return {
      name: menu.menuTitle,
      link: replaceBaseURL(menu.menuUrl || ""),
      submenus: menu.submenus?.map((submenu) => ({
        name: submenu.submenuTitle,
        link: replaceBaseURL(submenu.submenuUrl || ""),
      })) || [],
    };
  });
  



  return (
    <div className="middle-header">
      <HeaderTopbar emailAndPhone={emailAndPhone} />
      <div className="header-style-3">
        <div className="container">
          <div className="header-content">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                <div className="logo">
                  <Link onClick={() => ClickHandler("/")} to="/" title="">
                    <img src={headerInfo?.headerLogo} alt="Logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 d-lg-block d-none">
                <nav>
                  <ul>
                  {formattedHeaderMenus?.length > 0 &&
                      formattedHeaderMenus.map((menu) => (
                        <li
                          key={menu._id}
                          className={`menu-item ${
                            activeLink === `${menu.link}` ? "active" : ""
                          }`}
                        >
                          <Link
                            onClick={() => ClickHandler(menu.link)}
                            to={menu.link}
                            title=""
                          >
                            {menu.name}
                          </Link>
                          {menu.submenus?.length > 0 && (
                            <ul className="submenu">
                              {menu.submenus.map((submenu) => (
                                <li key={submenu._id}>
                                  <Link to={submenu.link} title="">
                                    {submenu.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </nav>
              </div>
              <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                <div className="contact">
                  <div className="cart-search-contact">
                    <div className="header-search-form-wrapper">
                      <button className="search-toggle-btn">
                        <FaSearch />
                      </button>
                      <div className="header-search-form">
                        <form onSubmit={SubmitHandler}>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                            />
                            <button type="submit">
                              <FaSearch />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <MobileMenu formattedHeaderMenus={formattedHeaderMenus}/>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
