import React from 'react'
import { Link } from 'react-router-dom'
import Newsletter from '../Newsletter'
import './style.css'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { GrLocation } from 'react-icons/gr'
import { MdCall, MdEmail } from 'react-icons/md'
import { footerApi } from '../../redux/apis/footerApi'

const Footer = (props) => {
    const id = "674d5940535c1c601142ff90";
    const { data: footerData, isLoading } = footerApi.useGetFooterByIdQuery(id);
    const footerInfo = footerData?.data;
    const { aboutSection, servicesSection, usefulLinks, contactSection } = footerInfo || {};
    const { socialLinks } = aboutSection || {};
    
    const servicesManus = servicesSection?.manus || [];
    const formattedServiceManus = servicesManus.map((menu) => ({
        name: menu.name,
        link: menu.link.replace("https://aqsfoundation.org", "") // Remove base URL
      }));

    const usefulManus = usefulLinks?.manus || [];
    const formattedUsefulManus = usefulManus.map((menu) => ({
        name: menu.name,
        link: menu.link.replace("https://aqsfoundation.org", "") // Remove base URL
      }));

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    if(isLoading){
        return <p>Loading..</p>
    }

    return (
        <div className={`wpo-ne-footer ${props.footerClass}`}>
            <Newsletter />
            <footer className="wpo-site-footer">
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-12">
                                <div className="widget about-widget">
                                    <div className="logo widget-title">
                                        <img src={aboutSection?.footerLogo} alt="" />
                                    </div>
                                    <p>{aboutSection?.aboutUs} </p>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to={socialLinks?.facebook}> <FaFacebookF className=' text-white bg-blue-500 hover:bg-blue-800 p-1 rounded-full text-2xl'/> </Link></li>
                                        <li><Link onClick={ClickHandler} to={socialLinks?.tiwtter}> <FaTwitter className=' text-white bg-blue-500 hover:bg-blue-800 p-1 rounded-full text-2xl'/></Link></li>
                                        <li><Link onClick={ClickHandler} to={socialLinks?.instagram}> <FaInstagram className=' text-white bg-blue-500 hover:bg-blue-800 p-1 rounded-full text-2xl'/></Link></li>
                                        <li><Link onClick={ClickHandler} to={socialLinks?.youtube}> <FaYoutube className=' text-white bg-red-500 hover:bg-red-700 p-1 rounded-full text-2xl'/></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Service</h3>
                                    </div>
                                    <ul>
                                        { 
                                            formattedServiceManus?.length > 0 && formattedServiceManus?.map((manu, i) => <li key={i}><Link onClick={ClickHandler} to={manu?.link}>{manu?.name}</Link></li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-2 col-md-6 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        {
                                            formattedUsefulManus?.length > 0 && formattedUsefulManus?.map((manu, i) => <li key={i}><Link onClick={ClickHandler} to={manu?.link}>{manu?.name}</Link></li>)
                                        }
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 offset-lg-1 col-md-6 col-12">
                                <div className="widget market-widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Contact </h3>
                                    </div>
                                    <p>{contactSection?.message}</p>
                                    <div className="contact-ft">
                                        <ul>
                                            <li className=' flex gap-2'> <GrLocation className=' text-3xl' /> {contactSection?.address}</li>
                                            <li  className=' flex gap-2 items-center'> <MdCall  /> {contactSection?.contactNumber} </li>
                                            <li className=' flex gap-2 items-center'> <MdEmail  /> {contactSection?.email}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-lower-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xs-12">
                                <p className="copyright">&copy; 2024 AQS. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;