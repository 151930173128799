import apiService from "../api-service/api-service";

 export const imagesApi = apiService.injectEndpoints({
    endpoints: (builder) => ({

        // Upload a new image with an image file
        uploadImage: builder.mutation({
            query: (formData) => ({
                url: "images/upload-image?folderLocation=images",
                method: "POST",
                body: formData,
            }),
        }),

        // Get all images (with optional filters)
        getAllImages: builder.query({
            query: () => ({
                url: "images/images",
            }),
        }),

        // Get images for the home page
        getImagesForHome: builder.query({
            query: () => ({
                url: "images/home-images",
            }),
        }),

        // Get a specific image by ID
        getImageById: builder.query({
            query: (id) => ({
                url: `images/${id}`,
            }),
        }),

        // Update an image by ID
        updateImage: builder.mutation({
            query: ({ id, data }) => ({
                url: `images/${id}`,
                method: "PUT",
                body: data,
            }),
        }),

        // Delete an image by ID
        deleteImage: builder.mutation({
            query: (id) => ({
                url: `images/images/${id}`,
                method: "DELETE",
            }),
        }),

  
    }),
});



