import React from 'react';
import { useParams } from 'react-router-dom';
import  pagesApi  from '../../redux/apis/pagesApi';
import PageHeader from '../../components/shared/PageHeader';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const CustomPage = () => {
    const { slug } = useParams();
    const { data: pageData, isLoading } = pagesApi.useGetPageBySlugQuery(slug);
    const page = pageData?.data;

    if (isLoading) {
        return <p>Loading...</p>;
    }

    // Convert description from Draft.js JSON to HTML
    let descriptionHTML = "";
    try {
        // Fixing the escaping issue before parsing the content
        const rawContent = JSON.parse(page?.content);
        const contentState = convertFromRaw(rawContent);
        descriptionHTML = stateToHTML(contentState);
    } catch (error) {
        console.error("Error parsing description:", error);
        descriptionHTML = "<p>Description could not be loaded.</p>";
    }

    return (
        <div className='container'>
            <PageHeader pageTitle={page?.title} />

            <div
                className="mt-5"
                dangerouslySetInnerHTML={{ __html: descriptionHTML }}
            />
        </div>
    );
};

export default CustomPage;
