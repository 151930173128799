import React from 'react';
import {Link} from 'react-router-dom';
import './style.css';
import { blogsApi } from '../../redux/apis/blogsApi';

const BlogSection = () => {

    const { data: blogData }  = blogsApi.useGetThreeBlogsForHomeQuery();
    
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
     const blogs = blogData?.data || [];
     


     const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Short month (e.g., Jan)
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };
    return(
        <div className="blog-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title">
                        <span>From Our Blog</span>
                        <h2>Latest Blog</h2>
                    </div>
                </div>
                <div className="row">
                    {blogs?.map((blog) => (
                        <div className="col-lg-4 col-md-6 col-12 custom-grid  " key={blog?._id}>
                            <div className="blog-item h-[450px] bg-white relative">
                                <div className="blog-img">
                                <img src={blog?.image} alt="" />
                                </div>
                                <div className="blog-content">
                                    <h3><Link onClick={ClickHandler} to={blog?.link}>{blog?.title}</Link></h3>
                                    <ul className="post-meta">
                                        
                                        <li className=' flex gap-1 items-center '>  {formatDate(blog?.createdAt)}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogSection;