import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import { MdCall, MdEmail } from 'react-icons/md'
import useAuth from '../../hooks/auth/useAuth'
import { usersApi } from '../../redux/apis/usersApi'

const HeaderTopbar = ({emailAndPhone}) => {
    const email = localStorage.getItem("email");
    const {  logOut } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const { data: userData  } = usersApi.useGetUserByEmailQuery(email);
    const currentUser = userData?.data;

    const isAdminOrSuperAdmin = currentUser?.role === "Super Admin" || currentUser?.role === "Admin" || false;

      // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };



   return (
    <div className="topbar">
        <div className="container">
            <div className="row">
                <div className="col col-md-6 col-sm-12 col-12">
                    <div className="contact-intro">
                        <ul className=' flex flex-col md:flex-row'>
                            <li className=' flex gap-2 items-center'> <MdCall /> {emailAndPhone?.phone}</li>
                            <li className=' flex gap-2 items-center'> <MdEmail /> {emailAndPhone?.email}</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-md-6 col-sm-12 col-12">
                    <div className="contact-info ">
                        <ul className=' flex flex-row-reverse'>
                         {
                            !email ? (
                                 <>
                                       <li><Link to="/login">Login</Link></li>
                                       <li><Link to="/signup">Sign Up</Link></li>
                                 </>
                            ) :
                            (
                                <div className="profile-container relative inline-block text-sm opacity-100 mt-3">
                                  <img
                                    src={`${currentUser?.profilePicture}`}
                                    alt="user"
                                    className="w-10 h-10 rounded-full cursor-pointer"
                                    onClick={toggleDropdown}
                                  />
                                  {isOpen && (
                                    <div className="dropdown-content absolute right-0 -top-3 mr-10 w-40 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-400 shadow-md rounded-md z-[100]">
                                      <h4 className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500">
                                        {currentUser?.name}
                                      </h4>
                            
                                  
                              {
                                !isAdminOrSuperAdmin ? 
                                <button
                                onClick={() => logOut()}
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500 w-full text-start"
                              >
                                Logout
                              </button> : 
                                  <Link
                                  to={"/dashboard"}
                                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-500 w-full text-start"
                                >
                                  Dashboard
                                </Link>
                              } 
                                  
                                    </div>
                                  )}
                                </div>
                              )
                         }
                            <li><Link className="theme-btn" to="/donate">Donate Now</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
     </div>
    )
}

export default HeaderTopbar;