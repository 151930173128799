import React from 'react';
import ContactForm from '../ContactFrom'
import { TiWorld } from "react-icons/ti";
import { MdWifiCalling3 } from "react-icons/md";

import { FiMail } from "react-icons/fi";
import './style.css'

const Contactpage = () => {

    return (
        <section className="wpo-contact-form-map section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="contact-form">
                                    <h2>Get In Touch</h2>
                                    <ContactForm />
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="contact-map">
                                    <iframe title='contact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115845.03637955163!2d89.5179687534288!3d25.646716737373666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fd33c4b7b80b69%3A0x238a638aacb9cd1e!2sPost%20Office%20More%2C%20Ulipur%2CKurigram!5e1!3m2!1sen!2sbd!4v1730927093940!5m2!1sen!2sbd"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-info">
      <div className="row">
        {/* Office Address */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="info-item">
            <h2>House-46, Road-11, Dhaka-1216</h2>
            <div className="info-wrap">
              <div className="flex justify-center items-center info-icon">
                <TiWorld size={30} />
              </div>
              <div className="info-text">
                <span>Office Address</span>
              </div>
            </div>
          </div>
        </div>
        {/* Official Mail */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="info-item">
            <h2>info@aqsfoundation.org</h2>
            <div className="info-wrap">
              <div className="flex justify-center items-center info-icon-2">
                <FiMail size={30} />
              </div>
              <div className="info-text">
                <span>Official Mail</span>
              </div>
            </div>
          </div>
        </div>
        {/* Official Phone */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="info-item">
            <h2>+8801715-818483</h2>
            <div className="info-wrap">
              <div className="flex justify-center items-center info-icon-3">
              <MdWifiCalling3 size={30} />
              </div>
              <div className="info-text">
                <span>Official Phone</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;
