import React from "react";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import {  FaUpload } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { imagesApi } from "../../../redux/apis/imagesApi";

const Images = () => {
  const { data: imagesData, isLoading, refetch } = imagesApi.useGetAllImagesQuery();
  const [ deleteImage ] = imagesApi.useDeleteImageMutation();
  const [selectedImage, setSelectedImage] = useState(null);

 if(isLoading){
  return <p>Loading..</p>
 }

 const images = imagesData?.images || [];
console.log("image data: ", imagesData)

  // Function to delete an image
  const handleDeleteImage = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then( async(result) => {
      if (result.isConfirmed) {
        const res = await deleteImage(id)
        refetch();
         if(res){
          if (res.data)
            Swal.fire({
              title: "Deleted!",
              text: `Image has been deleted `,
              icon: "success",
            });
          }
         }    
    });
  };

  // Function to open modal and set the selected image data
  const handlePreview = (image) => {
    setSelectedImage(image);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="p-6">
      <div>
        {/* Search and Controls Row */}
        <div className="flex items-center space-x-4 justify-between">

           <h3 className=" text-2xl">All Images</h3>
          <Link
            to={`/dashboard/gallery-images-upload`}
            className="bg-blue-500 flex gap-2 dark:bg-gray-700 dark:hover:bg-gray-600 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            <FaUpload className="w-6 h-6  dark:text-gray-300" />
            Upload Image
          </Link>
        </div>

        {/* Image Display */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-10">
          {images.map((image) => (
            <div key={image?._id} className="relative group">
              {/* Image */}
              <div className="relative">
                <img
                  src={image?.image}
                  alt={`image ${image?.caption}`}
                  className="w-48 h-36 object-cover rounded-md"
                />
                {/* Black overlay on hover */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-60 transition-opacity rounded-md"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  {/* Preview text */}
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button
                      onClick={() => handlePreview(image)}
                      className="text-center px-3 py-2 bg-white dark:bg-gray-800 rounded-md"
                    >
                      Preview
                    </button>
                  </div>
                  <button
                    onClick={() => handleDeleteImage(image?._id)}
                    className="absolute top-0 right-0 opacity-0 group-hover:opacity-100"
                  >
                    <MdDelete className="text-white text-xl" />
                  </button>
                </div>
              </div>
              <div className="mt-2">{image.info}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full">
            <h2 className="text-xl font-bold mb-4">{selectedImage.caption}</h2>
            <img
              src={selectedImage.imageUrl}
              alt={selectedImage.caption}
              className="w-full h-auto mb-4 rounded-md"
            />
            <p>{selectedImage.info}</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Images;
