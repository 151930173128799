import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import tmr1 from '../../images/prayer-shape/2.png';
import tmr2 from '../../images/prayer-shape/1.png';
import tmr3 from '../../images/prayer-shape/3.png';
import VideoModal from '../ModalVideo';
import './style.css';
import { aboutApi } from '../../redux/apis/aboutApi';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const About = () => {
  const [prayerTimes, setPrayerTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const id = "67504a7163926b25f61cb6f3";
  const { data: aboutData, isLoading } = aboutApi.useGetAboutByIdQuery(id);

  

  const about = aboutData?.data || {};
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };



  const date = new Date();
  const currentMonth = date.toLocaleString("default", { month: "long" });
  const currentYear = date.getFullYear();

  // Fetch Prayer Times
  useEffect(() => {
    const fetchPrayerTimes = async () => {
      try {
        const response = await fetch(
          `https://api.aladhan.com/v1/timingsByCity?city=Dhaka&country=Bangladesh&method=2`
        );
        const data = await response.json();
        setPrayerTimes(data.data.timings);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching prayer times:", error);
      }
    };

    fetchPrayerTimes();
  }, []);


  if(isLoading){
    return <p>Loading..</p>
  }
  
    // Convert description from Draft.js JSON to HTML
    let descriptionHTML = "";
    try {
      const contentState = convertFromRaw(JSON.parse(about?.description));
      descriptionHTML = stateToHTML(contentState);
    } catch (error) {
      console.error("Error parsing description:", error);
      descriptionHTML = "<p>Description could not be loaded.</p>";
    }

  return (
    <div className="wpo-about-area section-padding">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="wpo-about-img-3">
                <img src={about?.image} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 colsm-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <span>About Azmain QS Foundation</span>
                  <h2>{about?.title}</h2>
                </div>
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                />                <div className="btns">
                  <Link onClick={ClickHandler} to="/about" className="theme-btn" tabIndex="0">Discover More</Link>
                  <ul>
                    <li className="video-holder">
                      <VideoModal />
                    </li>
                    <li className="video-text">

                      Watch Our Video
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timer-section">
          <div className="row">
            <div className="col-lg-5">
              <div className="timer-text">
                <h2>Prayer Times</h2>
                <p>Prayer times in Bangladesh</p>
                <span>{currentMonth}, {currentYear}</span>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="timer-num">
                {loading ? (
                  <p>Loading...</p>
                ) : prayerTimes &&(
                  <ul>
                    <li>Fajr<span>{prayerTimes.Fajr}</span></li>
                    <li>Sunrise<span>{prayerTimes.Sunrise}</span></li>
                    <li>Dhuhr<span>{prayerTimes.Dhuhr}</span></li>
                    <li>Asr<span>{prayerTimes.Asr}</span></li>
                    <li>Maghrib<span>{prayerTimes.Maghrib}</span></li>
                    <li>Isha<span>{prayerTimes.Isha}</span></li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="timer-shape1">
            <img src={tmr1} alt="" />
          </div>
          <div className="timer-shape2">
            <img src={tmr2} alt="" />
          </div>
          <div className="timer-shape3">
            <img src={tmr3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
