import React from "react";
import "./imageGallery.css";
import { imagesApi } from "../../redux/apis/imagesApi";
// import Image_1 from "../images/image1.jpg";
// import Image_2 from "../images/image2.jpg";
// import Image_3 from "../images/image3.jpg";

// const images = [
//   {
//     id: 1,
//     image: Image_1,
//     title: "Image Title 1",
//     description: "This is a brief description for Image 1."
//   },
  
//   {
//     id: 2,
//     image: Image_2,
//     title: "Image Title 2",
//     description: "This is a brief description for Image 2."
//   },
//   {
//     id: 3,
//     image: Image_3,
//     title: "Image Title 3",
//     description: "This is a brief description for Image 3."
//   },
//   // Add more images here
// ];


const ImageGallery = () => {
  const { data: imagesData, isLoading } = imagesApi.useGetAllImagesQuery();

 if(isLoading){
  return <p>Loading..</p>
 }

 const images = imagesData?.images || [];
console.log("image data: ", imagesData)
  return (
    <div className=  "image-gallery container  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {images?.map((image) => (
        <div className="image-card" key={image?._id}>
          <div className="image-container">
            <img src={image?.image} alt={image?.caption} />
            <h2 className="title">{image?.caption}</h2>
            <div className="description">
              <p className="text">{image?.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;



