import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { developmentApi } from "../../../redux/apis/developmentApi";

const UpdateDevelopment = () => {
  const { id } = useParams();
  const { register, handleSubmit, formState: { errors }} = useForm();
  const [imageFile, setImageFile] = useState(null);
  const [selectedImage ,setSelectedImage] = useState(null);

  const { data: devData , isLoading, refetch} = developmentApi.useGetDevelopmentByIdQuery(id);
  const [ updateDevelopment ] = developmentApi.useUpdateDevelopmentMutation();
  const [ updateDevelopmentWithImage ] = developmentApi.useUpdateDevelopmentWithImageMutation();
  const  currentDev = devData?.data;

  

   useEffect(() => {
     setSelectedImage(currentDev?.image)
   }, [currentDev])

if(isLoading){
  return <p>Loading...</p>
}

//  handle add category function
  const handleUpdateCategory = async(data) => {
    try{
      let res;
      if(!imageFile){
        const filterData = { ...data }; // copy
        const excludeFields = ['image'];
        excludeFields.forEach((el) => delete filterData[el]);
  
        res = await updateDevelopment({ id, formData: filterData});
      } else {
        const formData = new FormData();
        formData.append("title", data?.title);
        formData.append("subtitle", data?.subtitle);
        formData.append("image", imageFile);
        formData.append("description", data?.description);
        res = await updateDevelopmentWithImage({ id,  formData})
      }
      
      if(res?.data?.success){
          refetch()
            toast.success(res?.data?.message)
        }
    } catch(error){
        toast.error(error?.data?.message)
        console.log(error)
    }
  };



   // Handle image change function
   const handleImageChange = (files) => {
     if (files && files.length > 0) {
       const imageFile = files[0];
       setImageFile(imageFile);

       const imageUrl = URL.createObjectURL(imageFile);
       setSelectedImage(imageUrl);
     }
   }
        // Handle image delete function
    const handleImageDelete = () => {
      setSelectedImage(null);
      document.querySelector("#image").value = null;
    };


  return (
    <div className=" container bg-gray-50  rounded-lg p-6 mt-3">
       <div className=" flex justify-between px-5">
       <h2 className="text-xl font-bold text-center text-gray-800 mb-6">Update development</h2>
       <Link to={"/dashboard/developments"} className="text-xl font-bold text-center text-white mb-6  px-2 py-1 bg-blue-500 rounded ">All Developments</Link>

       </div>
      <form onSubmit={handleSubmit(handleUpdateCategory)} className=" max-w-3xl mx-auto bg-white shadow-lg  p-6 ">
        {/* title */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="title" className="block text-gray-700 font-medium mb-2 col-span-1">
            Title
          </label>
          <input
            id="title"
            type="text"
            {...register("title", { required: "title is required" })}
            className={`w-full px-3 py-1 border col-span-3  ${
              errors.title ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter  title"
            defaultValue={currentDev?.title}
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
          )}
        </div>
        {/* subtitle */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="subtitle" className="block text-gray-700 font-medium mb-2 col-span-1">
            Subtitle
          </label>
          <input
            id="subtitle"
            type="text"
            {...register("subtitle", { required: "subtitle is required" })}
            className={`w-full px-3 py-1 border col-span-3  ${
              errors.subtitle ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter subtitle"
            defaultValue={currentDev?.subtitle}
          />
          {errors.subtitle && (
            <p className="text-red-500 text-sm mt-1">{errors.subtitle.message}</p>
          )}
        </div>

        {/* Description */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="description" className="block text-gray-700 font-medium mb-2 col-span-1">
            Description
          </label>
          <textarea
            id="description"
            {...register("description", { required: "Description is required" })}
            className={`w-full px-3 py-1 border col-span-3 ${
              errors.description ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter category description"
            rows="5"
            defaultValue={currentDev?.description}
          ></textarea>
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
          )}
        </div>


        {/* category image */}
                <div className=" mt-3 grid grid-cols-4 gap-2">
                   <label htmlFor="image" className="block text-sm font-medium text-gray-700 col-span-1">
                         Image <span className=" text-red-500 ">{"*"}</span>
                   </label>

                 <div className=" col-span-3">
                            
                <div className="cursor-pointer mt-3 p-3 border-dashed border-2 border-gray-300 bg-gray-50 dark:bg-gray-600 rounded-md flex items-center justify-center relative">
                  {selectedImage ? (
                    <>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-[250px] object-cover rounded-md"
                      />
                      <button
                        onClick={handleImageDelete}
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none hover:bg-red-600"
                      >
                        <AiOutlineClose className="text-lg" />
                      </button>
                    </>
                  ) : (
                    <span
                      onClick={() => document.querySelector("#image").click()}
                      className="flex flex-col items-center h-[200px] "
                    >
                      <span className="text-3xl dark:text-gray-300 text-gray-400">
                        📁
                      </span>
                      <p className="mt-2 text-sm dark:text-gray-300 text-gray-500 p-2 border rounded-md">
                        Select Image
                      </p>
                    </span>
                  )}
                </div>
        
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  className="hidden"
                  {...register("image")}
                  onChange={(e) => handleImageChange(e.target.files)}
                />
                {errors.image && (
                  <span className="text-red-500">This field is required</span>
                )}
                 </div>
        
                </div>

        {/* Submit Button */}
<div className=" flex justify-end">
<button
          type="submit"
          className="w-[200px]  mt-2 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Save change
        </button>
</div>
      </form>
    </div>
  );
};

export default UpdateDevelopment;
