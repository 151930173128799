import React from "react";
import { Link } from "react-router-dom";
import { categoryApi } from "../../../redux/apis/categoryApi";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";


const Categories = () => {
   const { data: categoryData, isLoading, refetch } = categoryApi.useGetAllCategoryQuery();
   const [deleteCategory ] = categoryApi.useDeleteCategoryMutation();

   const categories = categoryData?.data?.categories || [];



   const handleDeleteCategory = async (id, categoryName) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${categoryName}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id).then((res) => {
          if (res.data)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${categoryName} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container mt-5">
          <div className=" flex justify-between  md:px-4 mb-3 ">
            <h3 className=" text-xl font-semibold">All Categories</h3>
            <Link to={`/dashboard/add-category`} className=" px-2 py-1 bg-blue-500 hover:bg-blue-700 rounded text-white text-[16px]">Add New Category</Link>
          </div>
<div className="container overflow-x-auto">
  <table className="table table-zebra">
    {/* head */}
    <thead>
      <tr>
        <th>Si</th>
        <th>Name</th>
        <th>Description</th>
        <th>Type</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}

      {
        (!isLoading && categories) ?   (categories?.map((category, i) =>  (<tr key={category?._id} className=" bg-white">
        <th>{i + 1}</th>
        <td>{category?.name}</td>
        <td>{category?.description}</td>
        <td className=" text-blue-500 hover:text-white bg-blue-500 hover:bg-blue-700 px-2 py-1  rounded-xl text-center">{category?.type}</td>
        <td className=" flex gap-2">
          <Link to={`/dashboard/edit-category/${category?._id}`}> <FaEdit className=" text-green-500 text-xl "/> </Link>
          <MdOutlineDeleteForever onClick={( ) => handleDeleteCategory(category?._id, category?.name)} className=" text-rose-600 text-xl"/>
     
        </td>
      </tr>)))  
      : 
      <p>Categories not found</p>
      }
  

    </tbody>
  </table>
</div>        </div>
    );
};

export default Categories;