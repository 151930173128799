import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import  pagesApi  from "../../../redux/apis/pagesApi";

const AllPages = () => {
   const { data: pagesData, isLoading, refetch } = pagesApi.useGetAllPagesQuery();
   const [ deletePage ] = pagesApi.useDeletePageMutation();
   const [hoveredPageId, setHoveredPageId] = useState(null); // Track hovered page

   const pages = pagesData?.data || [];


   console.log("pages: ", pages)

   const handleDeletePage = async (id, pageTitle) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${pageTitle}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePage(id).then((res) => {
          if (res.data)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${pageTitle} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container mt-5">
          <div className=" flex justify-between  md:px-4 mb-3 ">
            <h3 className=" text-xl font-semibold">All pages</h3>
            <Link to={`/dashboard/header`} className=" px-2 py-1 bg-blue-500 hover:bg-blue-700 rounded text-white text-[16px]">Add New page</Link>
          </div>
             <div className="container overflow-x-auto">
               <table className="table table-zebra">
                 {/* head */}
                 <thead>
                   <tr>
                     <th>Si</th>
                     <th>Title</th>
                     <th>Link</th>
                     <th>Submenus</th>
                     <th>Action</th>
                   </tr>
                 </thead>
                 <tbody>
                   {/* row 1 */}
             
                   {
                     (!isLoading && pages) ?   (pages?.map((page, i) =>  (<tr key={page?._id} className=" bg-white">
                     <th>{i + 1}</th>
                     <td>{page?.title}</td>
                     <td>{page?.link}</td>
                     <td
                    className="relative"
                    onMouseEnter={() => setHoveredPageId(page?._id)}
                    onMouseLeave={() => setHoveredPageId(null)}
                  >
                    {page?.submenus?.length > 0 ? (
                      <button className="px-2 py-1 bg-gray-300 hover:bg-gray-400 rounded">
                        Submenus
                      </button>
                    ) : (
                      ""
                    )}
                    {/* Submenu Dropdown */}
                    {hoveredPageId === page?._id && (
                      <div className="absolute -left-24  mt-2 bg-white shadow-lg rounded p-3 z-10">
                        {page?.submenus?.map((submenu, idx) => (
                          <div
                            key={idx}
                            className="flex justify-between items-center p-2 hover:bg-gray-100 rounded"
                          >
                            <div>
                              <p className="font-semibold">{submenu?.submenuTitle}</p>
                              <a
                                href={submenu?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 text-sm"
                              >
                                {submenu?.submenuUrl}
                              </a>
                            </div>
                            <div className="flex gap-2">
                              <Link to={`/dashboard/update-subpage/${page?._id}/${submenu?._id}`}>
                                <FaEdit className="text-green-500 text-lg" />
                              </Link>
                              <MdOutlineDeleteForever
                                className="text-rose-600 text-lg cursor-pointer"
                                onClick={() =>
                                  Swal.fire(
                                    "Feature Coming Soon",
                                    "Delete functionality for submenus is not yet implemented",
                                    "info"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </td>
                     
                     {
                      i > 4 &&  <td className=" flex gap-2">
                      <Link to={`/dashboard/edit-page/${page?._id}`}> <FaEdit className=" text-green-500 text-xl "/> </Link>
                      <MdOutlineDeleteForever onClick={( ) => handleDeletePage(page?._id, page?.title)} className=" text-rose-600 text-xl"/>
                    </td>
                     }
        
                   </tr>)))  
                   : 
                   <p>pages not found</p>
                   }
             
                 </tbody>
               </table>
             </div>      
          </div>
  );
};

export default AllPages;