import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai"; // Importing the close icon for delete functionality
import { FaAlignJustify, FaUpload } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { imagesApi } from "../../../redux/apis/imagesApi";
import { categoryApi } from "../../../redux/apis/categoryApi";

const UploadImage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState();
  const [ uploadImage ] = imagesApi.useUploadImageMutation();
  const { data: categoryData } = categoryApi.useGetAllCategoryQuery();

  const categories = categoryData?.data?.categories || [];

  // handle image change function
  const handleImageChange = (files) => {
    if (files && files?.length > 0) {
      const imageFile = files[0];
      setImage(imageFile);
      const imageUrl = URL.createObjectURL(imageFile);
      setSelectedImage(imageUrl);
    }
  };

  // handle image delete function
  const handleImageDelete = () => {
    setSelectedImage(null);
    // Reset the file input
    document.querySelector("#image").value = null;
  };

  //   handle upload image function
  const handleImageUpload = async (data) => {
    try {
      const formData = new FormData();

      formData.append("caption", data?.caption);
      formData.append("description", data?.description);
      formData.append("image", image);
      formData.append("usageType", data?.usageType);
      formData.append("category", data?.category);

      const res = await uploadImage( formData);
      console.log(res.data)
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        reset();
        setSelectedImage(null)
      }
    } catch (error) {
      toast.error(error.message);
      console.log("error image uploading: ", error);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-5 rounded-md w-full max-w-2xl  mb-5">
      <div className="flex justify-between">
        <h3 className="text-xl font-semibold">Add Image</h3>
        <Link
          className="text-white bg-green-400 rounded-md flex gap-1 py-2 items-center px-3 w-24 mb-2"
          to={`/dashboard/gallery-images`}
        >
          <FaAlignJustify /> Images
        </Link>
      </div>
      <form onSubmit={handleSubmit(handleImageUpload)}>
        <div className="">
          {/* Image caption Input */}
          <div className="mt-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="imageCaption"
            >
              Image Caption
            </label>
            <input
              type="text"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Image caption"
              {...register("caption")}
            />
          </div>
          {/* Image Description Input */}
          <div className="mt-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="imageDescription"
            >
              Image Description
            </label>
            <input
              type="text"
              id="imageDescription"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Image Description"
              {...register("imageDescription")}
            />
          </div>
     
          {/* category Select */}
          <div className="mt-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="category"
            >
              Category
            </label>
            <select
              id="category"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("category")}
            >
              <option value="">Select a category</option>
              {
                categories?.length > 0 && categories?.map(( cat) =>  <option key={cat?._id} value={cat?._id}>{cat?.name}</option> )
              }
       
            </select>
          </div>
          {/* image section  */}
          <div className="bg-white  rounded-md w-full max-w-lg my-5">
            {/* Heading */}
            <h2 className="text-lg font-semibold">Image</h2>

            {/* Image Upload Section */}
            <div className="cursor-pointer  p-6 border-dashed border-2 border-gray-300 bg-gray-50 rounded-md flex items-center justify-center relative">
              {selectedImage ? (
                // If image is selected, show the image preview with a delete button
                <>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-full h-48 object-cover rounded-md"
                  />
                  {/* Delete Button */}
                  <button
                    onClick={handleImageDelete}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none hover:bg-red-600"
                  >
                    <AiOutlineClose className="text-lg" />
                  </button>
                </>
              ) : (
                // Show the upload icon and text when no image is selected
                <span
                  onClick={() => document.querySelector("#image").click()}
                  className="flex flex-col items-center"
                >
                  <span className="text-3xl text-gray-400">📁</span>
                  <p className="mt-2 text-sm text-gray-500 p-2 border rounded-md">
                    Select Image
                  </p>
                </span>
              )}
            </div>

            <input
              type="file"
              id="image"
              accept="image/*"
              className="hidden"
              {...register("image", { required: true })}
              onChange={(e) => handleImageChange(e.target.files)}
            />
            {errors.image && (
              <span className="text-red-500">This field is required</span>
            )}
          </div>
          {/*  */}
          <button
            type="submit"
            className="py-2 px-3 bg-blue-500 hover:bg-blue-700 rounded-md flex gap-2 text-white dark:bg-gray-700 items-center text-center"
          >
            Upload <FaUpload className=" text-sm" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadImage;
