import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { MdAddTask } from "react-icons/md";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import { usersApi } from "../../../redux/apis/usersApi";
import { eventsApi } from "../../../redux/apis/eventsApi";
import { toast } from "react-toastify";
import { categoryApi } from "../../../redux/apis/categoryApi";
import { Link } from "react-router-dom";
import { FaAlignJustify } from "react-icons/fa";

const AddEvent = () => {
  const [imageFile, setImageFile] = useState(null);
  const [  selectedImage ,setSelectedImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const email = localStorage.getItem("email");
  const { data: user } = usersApi.useGetUserByEmailQuery(email);
  const { data: categoryData, isLoading: categoryLoading } = categoryApi.useGetCategoriesByTypeQuery("event");
  const [ createEvent ] = eventsApi.useCreateEventMutation();
  const { register, handleSubmit, reset, formState: { errors }} = useForm();
  const [startDate, setStartDate] = useState(""); // State to track selected start date
  const [endDate, setEndDate] = useState(""); // State to track selected end date


    //  handle text editor
    const onEditorStateChange = (newState) => {
      setEditorState(newState);
    };

    const categories = categoryData?.data; 



    // Handle image change function
    const handleImageChange = (files) => {
        if (files && files.length > 0) {
          const imageFile = files[0];
          setImageFile(imageFile);
    
          const imageUrl = URL.createObjectURL(imageFile);
          setSelectedImage(imageUrl);
        }
      };

      // Handle image delete function
  const handleImageDelete = () => {
    setSelectedImage(null);
    document.querySelector("#image").value = null;
  };

   // Handle tags input
   const handleTagInput = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      setTags([...tags, e.target.value.trim()]);
      e.target.value = ""; // Clear input field
    }
  };

  // Remove a tag
  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };


    // Handle startDate change
    const handleStartDateChange = (e) => {
      const selectedDate = e.target.value;
      setStartDate(selectedDate);
      if (endDate && new Date(endDate) < new Date(selectedDate)) {
        setEndDate(""); // Reset endDate if it is before the new startDate
      }
    };
  
    // Handle endDate change
    const handleEndDateChange = (e) => {
      setEndDate(e.target.value);
    };

    // handle add event function
 const handleAddEvent = async(data) => {
      try{
       const formData = new FormData();
   
       formData.append("title", data?.title);
       formData.append("image", imageFile);
       formData.append("authorId", user?.data?._id);
       formData.append("shortDescription", data?.shortDescription);
       const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
       formData.append("description", JSON.stringify(rawContent));       
       formData.append("location", data?.location);
       formData.append("categoryId", data?.categoryId);
       formData.append("startDate", data?.startDate);
       formData.append("endDate", data?.endDate);
       formData.append("organizer", data?.organizer);
       formData.append("isOnline", data?.isOnline);
   
       if(tags.length > 0 ){
         formData.append("tags", JSON.stringify(tags));
       }

   
       const res = await createEvent(formData).unwrap();
       
       if(res?.success){
        reset()
        setSelectedImage(null);
        setEditorState(null)
         toast.success(res?.message)
       }
      } catch(error){
       console.log(error)
        toast.error(error?.data?.message)
      }
       // Clear form after submission
     };
   
  return (
    <div className=" container mx-auto p-6 bg-white shadow-md rounded-md mt-10">

      <div className=" flex justify-between items-center">
       <h2 className="text-2xl font-semibold text-gray-800 mb-6">Add New Event</h2>
         <Link to={"/dashboard/all-events"} className=" flex gap-2 items-center text-white bg-blue-600 hover:bg-blue-800 px-2 py-1 rounded-md"> <FaAlignJustify /> All Events </Link>
       </div>
    
      <form onSubmit={handleSubmit(handleAddEvent)} className="space-y-6">

        <div className=" w-full flex gap-3 flex-col lg:flex-row">
                 {/* title & description */}
        <div className=" flex-1">
        {/* Title */}
        <div className="mt-3">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Event Title <span className=" text-red-500">{"*"}</span>
          </label>
          <input
            id="title"
            type="text"
            {...register("title", { required: "Title is required" })}
            className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter event title"
          />
          {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>}
        </div>

        {/* Location */}
         <div className="mt-3">
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
            Location <span className=" text-red-500">{"*"}</span>
          </label>
          <input
            id="location"
            type="text"
            {...register("location", { required: "Location is required" })}
            className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter location"
          />
          {errors.location && <p className="text-red-500 text-sm mt-1">{errors.location.message}</p>}
        </div>

        {/*Short description */}
        <div className="mt-3">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
           Short Description <span className=" text-red-500">{"*"}</span>
          </label>
          <textarea
            id="shortDescription"
            {...register("shortDescription", { required: "Description is required" })}
            className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            rows="4"
            placeholder="Enter event description"
          ></textarea>
          {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>}
        </div>

              {/* Category Dropdown */}
              <div className="mt-3">
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                Category <span className=" text-red-500">{"*"}</span>
              </label>
              <select
                id="categoryId"
                {...register("categoryId", { required: "CategoryId is required" })}
                className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="">Select a category</option>
                {!categoryLoading && categories?.map((category) => (
                  <option key={category?._id} value={category?._id}>
                    {category?.name}
                  </option>
                ))}
              </select>
              {errors.category && <p className="text-red-500 text-sm mt-1">{errors.category.message}</p>}
            </div>
 
       {/* Schedule start */}
       <div className="mt-3">
             <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                Schedule Start Date & Time <span className="text-red-500">{"*"}</span>
              </label>
              <input
                id="startDate"
                type="datetime-local"
                {...register("startDate", { required: "Start Date is required" })}
                className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                min={new Date().toISOString().slice(0, 16)} // Disable past dates
                value={startDate}
                onChange={handleStartDateChange}
              />
              {errors.startDate && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.startDate.message}
                </p>
              )}
            </div>

            {/* Tags Input */}
            <div className="mt-3">
              <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                Tags
              </label>
              <div className="flex flex-wrap items-center gap-2">
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="flex items-center gap-1 px-2 py-1 bg-blue-100 text-blue-600 rounded-full"
                  >
                    {tag}
                    <AiOutlineClose
                      className="cursor-pointer text-red-500 text-sm"
                      onClick={() => removeTag(index)}
                    />
                  </span>
                ))}
              </div>
              <input
                id="tags"
                type="text"
                onKeyDown={handleTagInput}
                
                className="mt-2 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Enter a tag and press Enter"
              />
            </div>
        </div>
        {/* Image */}
        <div className=" flex-1">
        <div className=" mt-3">
        <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                Event image <span className=" text-red-500">{"*"}</span>
           </label>

        <div className="cursor-pointer mt-3 p-3 border-dashed border-2 border-gray-300 bg-gray-50 dark:bg-gray-600 rounded-md flex items-center justify-center relative">
          {selectedImage ? (
            <>
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full h-[290px] object-cover rounded-md"
              />
              <button
                onClick={handleImageDelete}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none hover:bg-red-600"
              >
                <AiOutlineClose className="text-lg" />
              </button>
            </>
          ) : (
            <span
              onClick={() => document.querySelector("#image").click()}
              className=" flex flex-col items-center  h-[290px] "
            >
             <span className="flex flex-col items-center my-auto">
             <span className="text-3xl dark:text-gray-300 text-gray-400">
                📁
              </span>
              <p className="mt-2 text-sm dark:text-gray-300 text-gray-500 p-2 border rounded-md">
                Select Image
              </p>
             </span>
            </span>
          )}
        </div>

        <input
          type="file"
          id="image"
          accept="image/*"
          className="hidden"
          {...register("image", { required: true })}
          onChange={(e) => handleImageChange(e.target.files)}
        />
        {errors.image && (
          <span className="text-red-500">This field is required</span>
        )}

        </div>


         {/* Schedule end */}
         <div className="mt-3">
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700"
              >
                Schedule End Date & Time <span className=" text-red-500">{"*"}</span>
              </label>
              <input
                id="endDate"
                type="datetime-local"
                {...register("endDate", { required: "End Date is required" })}
                className="mt-1 block w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                min={startDate || new Date().toISOString().slice(0, 16)} // Disable dates before startDate
                value={endDate}
                onChange={handleEndDateChange}
              />
              {errors.endDate && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.endDate.message}
                </p>
              )}
            </div>

   
       
       {/* <div className=" mt-3 flex gap-2">
   
            <input type="checkbox" {...register("isOnline")} value="true" placeholder="is Online?"/>
            <label htmlFor="isOnline" className="block text-sm font-medium text-gray-700">
            is Online? 
              </label>
       </div> */}

        </div>
 
 


        </div>


        {/* Schedule Description */}
          <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
           Description 
          </label>
     <Editor
       editorState={editorState}
       toolbarClassName="border border-gray-300 rounded-t-md p-2"
       wrapperClassName="border border-gray-300 rounded-md"
       editorClassName="p-3 min-h-[200px] rounded-b-md focus:outline-none"
       onEditorStateChange={onEditorStateChange}
     />
          </div>
        {/* Submit Button */}
        <div className=" flex justify-end">
          <button
            type="submit"
            className=" flex items-center gap-2 w-[200px] px-4 py-2 bg-white bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <MdAddTask className=" text-white text-xl" />

            Add Event
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEvent;
