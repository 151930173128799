import React, { useState } from 'react';
import './style.css';
import { newsletterApi } from '../../redux/apis/newsletterApi';
import { toast } from 'react-toastify';

const Newsletter = () => {
    const [subscribeNewsLetter] = newsletterApi.useSubscribeNewsLetterMutation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const SubmitHandler = async (e) => {
        e.preventDefault();
        setError('');

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
           const res =  await subscribeNewsLetter( {email} ).unwrap();

           console.log(res)
           if(res?.success){
            toast.success(res?.message)
           }
            setEmail('');
        } catch (error) {
            setError('Failed to subscribe. Please try again.');
        }
    };

    return (
        <section className="wpo-news-letter-section">
            <div className="container">
                <div className="wpo-news-letter-wrap">
                    <div className="row">
                        <div className="col col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                            <div className="wpo-newsletter">
                                <h3>Follow us For further information</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                                <div className="wpo-newsletter-form">
                                    <form onSubmit={SubmitHandler}>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Enter Your Email"
                                                name="email"
                                                className="form-control"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <button type="submit">Subscribe</button>
                                        </div>
                                        {error && <p className="error-message">{error}</p>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Newsletter;
