import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { eResourceApi } from "../../../redux/apis/eresourceApi";

const AllPdf = () => {
   const { data: eresourcesData, isLoading, refetch } = eResourceApi.useGetAllEresourcesQuery();
   const [deleteEresource ] = eResourceApi.useDeleteEresourceMutation();

   const eresources = eresourcesData?.data?.eresources || [];


   const handleDeleteEresurece = async (id, pdfTitle) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${pdfTitle}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEresource(id).then((res) => {
          if (res?.data?.success)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${pdfTitle} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container mt-5">
          <div className=" flex justify-between  md:px-4 mb-3 ">
            <h3 className=" text-xl font-semibold">All pdf </h3>
            <Link to={`/dashboard/upload-pdf`} className=" px-2 py-1 bg-blue-500 hover:bg-blue-700 rounded text-white text-[16px]">Add New Pdf</Link>
          </div>
<div className="container overflow-x-auto">
  <table className="table table-zebra bg-white">
    {/* head */}
    <thead>
      <tr>
        <th>Si</th>
        <th>Title</th>
        <th>Category</th>
        <th>Author</th>
        <th>Created at</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody className=" bg-white">
      {/* row 1 */}

      {
        (!isLoading && eresources) ?   (eresources?.map((eresurece, i) =>  (<tr key={eresurece?._id} className=" bg-white">
        <th>{i + 1}</th>
        <td>{eresurece?.title}</td>
        <td  className=" text-blue-500 hover:text-white bg-blue-500 hover:bg-blue-700 px-2 py-1  rounded-xl text-center">{eresurece?.categoryId?.name}</td>
        <td>{eresurece?.userId?.name}</td>
        <td> {new Date(eresurece?.createdAt).toLocaleString()}</td>
        <td className=" flex gap-2">
          <Link to={`/dashboard/edit-eresurece/${eresurece?._id}`}> <FaEdit className=" text-green-500 text-xl "/> </Link>
          <MdOutlineDeleteForever onClick={( ) => handleDeleteEresurece(eresurece?._id, eresurece?.title)} className=" text-rose-600 text-xl"/>
     
        </td>
      </tr>)))  
      : 
      <p>Eresources not found</p>
      }
  
    </tbody>
  </table>
</div>        </div>
    );
};

export default AllPdf;