import apiService from "../api-service/api-service";

export const developmentApi = apiService.injectEndpoints({
    endpoints: (builder) => ({
        // Create a new development
        createDevelopment: builder.mutation({
            query: (formData) => ({
                url: "development/create-development?locationFolder=development-images",
                method: "POST",
                body: formData
            }),
        }),

        // Get all developments
        getAllDevelopments: builder.query({
            query: () => ({
                url: "development/all-developments"
            })
        }),

        // Get a specific development by id
        getDevelopmentById: builder.query({
            query: (id) => ({
                url: `development/${id}`
            })
        }),

        // Get a specific getDevelopmentByLatestOne
        getDevelopmentByLatestOne: builder.query({
            query: () => ({
                url: `development/latest/one`
            })
        }),

        // Update a development by id
        updateDevelopment: builder.mutation({
            query: ({ id, formData }) => ({
                url: `development/update/${id}`,
                method: "PATCH",
                body: formData
            })
        }),

        // Update a development by id with image
        updateDevelopmentWithImage: builder.mutation({
            query: ({ id, formData }) => ({
                url: `development/${id}/update-with-image?locationFolder=development-images`,
                method: "PATCH",
                body: formData
            })
        }),

        // Delete a development by id
        deleteDevelopment: builder.mutation({
            query: (id) => ({
                url: `development/delete/${id}`,
                method: "DELETE"
            })
        })
    }),
})
